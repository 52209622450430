import type { FC } from 'react'
import React from 'react'
import { useParseInputProps } from '@goatlab/react-zod-form'
import cn from 'clsx'
import { Controller } from 'react-hook-form'
import type { BaseField } from '../types/baseField'
import { Label } from '../Label'

type InputProps = BaseField<string> & {
  inputClassName?: string
  shadow?: boolean
  variant?: 'normal' | 'solid' | 'outline'
}

export const Input: FC<InputProps> = ({
  className = 'flex-1 mb-2',
  label,
  name: nameProp,
  placeholder,
  inputClassName,
  formHook: hook,
  defaultValue,
  disabled,
  transform,
  onChange,
  description,
  tabIndex,
}) => {
  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  const rootClassName = cn(
    'form-input min-h-10 w-full appearance-none rounded-md px-4 py-2 font-body text-xs text-black placeholder-body transition duration-150 duration-200 ease-in-out md:px-5 lg:text-sm',
    inputClassName,
    {
      'cursor-not-allowed bg-gray-200 text-slate-500': disabled,
    },
  )

  return (
    <div className={className}>
      {label && <Label text={label} />}
      <Controller
        control={formHook.control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange: hookChange, ...rest } }) => {
          return (
            <input
              id={name}
              tabIndex={tabIndex}
              type={'text'}
              placeholder={placeholder}
              disabled={disabled}
              className={rootClassName}
              autoComplete="nope"
              spellCheck="false"
              aria-invalid={errorMessage ? 'true' : 'false'}
              onChange={(event) => {
                if (transform) {
                  event.target.value = transform(event.target.value)
                }

                onChange?.(event.target.value)
                hookChange(event.target.value)
              }}
              {...rest}
            />
          )
        }}
      />
      <h4 className="mb-1 ml-2 mt-1 block text-xs font-medium">
        {description}
      </h4>
      {errorMessage && (
        <div className="mt-2 pl-1 text-xs text-red-600">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}
