import type { FC } from 'react'
import { api } from '@api/api'
import { useAuth } from '@components/auth/store/auth.store'
import { Input } from '@components/ui/forms/fields/Input'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Button } from '@goatlab/react-ui'
import { Form, useZodFormHook } from '@goatlab/react-zod-form'
import {
  useAccountStore,
  useCommentActivitiesStore,
  useHomeFeedStore,
} from '@sodium/shared-frontend-schemas'
import { z } from 'zod'

interface CommentInputProps {
  activityId?: string
  postId?: string
  onHandleComment?: () => void
}

export const commentSchema = z.object({
  comment: z.string(),
})

export const CommentInput: FC<CommentInputProps> = ({ activityId, postId }) => {
  const { user } = useAuth()
  const { selectedAccount } = useAccountStore()
  const { visibleAccountId } = useHomeFeedStore()
  const { resetCommentActivity } = useCommentActivitiesStore()

  const { t } = useTypedTranslation(['common', 'forms'])

  const formHook = useZodFormHook({
    schema: commentSchema,
    defaultValues: {
      comment: '',
    },
  })

  const handleReset = async () => {
    resetCommentActivity()
  }

  const postComment = api.posts.useCommentOnPost({
    account: selectedAccount,
    visibleAccountId,
    reset: handleReset,
  })

  const handleSubmitComment = async () => {
    const comment = formHook.getValues().comment

    if (!comment) {
      return
    }
    if (!selectedAccount) {
      return
    }

    if (!activityId && !postId) {
      return
    }

    if (!postId) {
      return
    }

    return await postComment.mutateAsync({
      postId,
      comment,
    })
  }
  return (
    <Form
      formHook={formHook}
      onSuccess={async () => {
        await handleSubmitComment()
        formHook.reset()
      }}
    >
      <div className="flex flex-row items-center justify-center gap-2 p-4">
        <Avatar className="h-5 w-5 md:h-7 md:w-7" data-cy="user-avatar">
          <AvatarImage
            src={user?.account?.profilePicture || user?.profilePicture}
            alt={user?.account?.firstName || undefined}
          />
          <AvatarFallback>{user?.account?.firstName}</AvatarFallback>
        </Avatar>
        <Input
          className="w-full items-center justify-center"
          name={formHook.path.comment}
          placeholder={t('placeholder-new-post', { ns: 'forms' })}
          formHook={formHook}
        />
        {/* <RichTextArea
            name={formHook.path.comment}
            label={t('placeholder-new-post', { ns: 'forms' })}
            formHook={formHook}
          />*/}
        <Button type="submit">{t('button-comment', { ns: 'common' })}</Button>
      </div>
    </Form>
  )
}
