import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  format,
  formatDistanceToNow,
} from 'date-fns'

export function fromNowWithDateFns(startedDate: string) {
  const date =
    typeof startedDate === 'string' ? new Date(startedDate) : startedDate
  return formatDistanceToNow(date, { addSuffix: true })
}

export function fromNowShort(startedDate: string): string {
  const date = new Date(startedDate)
  const now = new Date()

  const mins = Math.abs(differenceInMinutes(now, date))
  if (mins < 60) return `${mins}m`

  const hours = Math.abs(differenceInHours(now, date))
  if (hours < 24) return `${hours}h`

  const days = Math.abs(differenceInDays(now, date))
  if (days < 7) return `${days}d`

  const weeks = days / 7
  if (weeks < 4) return `${Math.floor(weeks)}w`

  const months = Math.abs(differenceInMonths(now, date))
  if (months < 12) return `${months}mo`

  const years = Math.abs(differenceInYears(now, date))
  return `${years}y`
}

// Nueva función para formatear a 12 horas con AM/PM
export function formatTo12HourTime(date: Date): string {
  return format(date, 'hh:mm a')
}
