import type { RouterOutputs } from '@api/trpc'
import type { PusherEvent } from 'pusher-js/types/src/core/connection/protocol/message-types'
import type { StateCreator } from 'zustand'
import { useAuth } from '@components/auth/store/auth.store'
import { create } from 'zustand'

export interface ChatUser {
  _id: string
  name: string
  avatar: string
}

export interface ChatMessage {
  _id: string
  text: string
  createdAt: Date
  user: ChatUser
  image?: string
  recipientId?: string
}

export interface ChatState {
  currentChatId?: string
  currentChatRecipient?: ChatUser
  chatMessages: ChatMessage[]
}

export type FrontendMessageModel =
  RouterOutputs['backend']['chats']['getMessagesByConversationId'][0]

export interface ChatActions {
  setCurrentChatId: (userOrConversationId?: string) => void
  setCurrentChatRecipient: (recipient?: ChatUser) => void
  appendMessages: (messages: ChatMessage[]) => void
  appendMessageFromPusher: (message: PusherEvent) => void
  setMessages: (messages: FrontendMessageModel[]) => void
  currentChatFriendId: () => string | undefined
  currentMessages: () => ChatMessage[]
  hasAccountChatId: () => boolean
}

const appendMessagesManually = (
  existingMessages: ChatMessage[],
  newMessages: ChatMessage[],
): ChatMessage[] => {
  return [...newMessages, ...existingMessages].sort(
    (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
  )
}

const createChatSlice: StateCreator<ChatState & { actions: ChatActions }> = (
  set,
  get,
) => ({
  currentChatId: undefined,
  currentChatRecipient: undefined,
  chatMessages: [],
  actions: {
    setCurrentChatId: (userOrConversationId) =>
      set({ currentChatId: userOrConversationId }),
    setCurrentChatRecipient: (recipient) =>
      set({ currentChatRecipient: recipient }),
    appendMessages: (messages) =>
      set((state) => ({
        chatMessages: appendMessagesManually(state.chatMessages, messages),
      })),
    appendMessageFromPusher: (message) => {
      const data = JSON.parse(message.data)

      const recipient: ChatUser = {
        _id: get().currentChatRecipient?._id || 'recipient-id',
        name: get().currentChatRecipient?.name || '',
        avatar: get().currentChatRecipient?.avatar || '',
      }

      const newMessage = {
        _id: `${data.localId}`,
        text: data.data,
        createdAt: data.createdAt,
        user: recipient,
      }

      set((state) => ({
        chatMessages: appendMessagesManually(state.chatMessages, [newMessage]),
      }))
    },
    setMessages: (messages) => {
      const recipient: ChatUser = {
        _id: get().currentChatRecipient?._id || 'recipient-id',
        name: get().currentChatRecipient?.name || '',
        avatar: get().currentChatRecipient?.avatar || '',
      }

      const user = useAuth.getState().user

      const currentUser: ChatUser = {
        _id: user?.account?.id || 'user-id',
        name: `${user?.account?.firstName} ${user?.account?.lastName}`,
        avatar: user?.profilePicture || '',
      }

      const newMessages: ChatMessage[] = messages.map((msg) => ({
        _id: `${msg.id}`,
        text: msg.content,
        createdAt: new Date(msg.created),
        recipientId: recipient._id,
        metadata: msg.assets,
        image: msg.assets[0]?.thumbnailUrl || msg.assets[0]?.url,
        user:
          msg.senderId === get().currentChatRecipient?._id
            ? recipient
            : currentUser,
      }))

      return set({ chatMessages: appendMessagesManually([], newMessages) })
    },
    currentChatFriendId: () => get().currentChatId,
    currentMessages: () => get().chatMessages,
    hasAccountChatId: () => {
      if (!get().currentChatId && !get().currentChatRecipient) return false
      return get().currentChatId === get().currentChatRecipient?._id
    },
  },
})

export const useChatStore = create<ChatState & { actions: ChatActions }>(
  createChatSlice,
)

export const useCurrentChatId = () =>
  useChatStore((state) => state.currentChatId)
export const useCurrentChatRecipient = () =>
  useChatStore((state) => state.currentChatRecipient)
export const useChatMessages = () => useChatStore((state) => state.chatMessages)
export const useChatActions = () => useChatStore((state) => state.actions)
